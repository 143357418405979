
body {
  background-color: #fafafa;
  color: #414141;
  font-family: Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

strong{
  font-weight: 600;
}

a, a:visited {
  text-decoration: none;
  color: #1552ff;
}

a:hover {
  color: #0f44d3;
}

hr {
  border-top: 1px solid #dbdbdb;
  margin: 20px 0;
}

.header {
  background: #fafafa;
  box-shadow: 0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 15%);
  padding: 11px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99990;
}

.header-wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 100px 1fr;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.5rem;
}

.header-wrapper.wrapper {
  display: flex;
  justify-content: center;
}

.search-container > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f9f9f9;

  color: #262627;
  font: inherit;
  height: 36px;
  padding: 0;
  width: 100%;

  border: 1px solid gray;
  border-radius: 3px;

  padding: 0 20px;

  -webkit-transition: all 0.10s ease-in-out;
  -moz-transition: all 0.10s ease-in-out;
  -ms-transition: all 0.10s ease-in-out;
  -o-transition: all 0.10s ease-in-out;  
  transition: all 0.10s ease-in-out;  

}


.search-container > input[type=text]:focus {
  box-shadow: 0 0 5px #7d75ff;
  border: 1px solid #3e34d3;

  outline: none;
}

.container {
  margin-top: 60px;
  padding: 25px
}

.search-container{
  display: flex;
  align-items: center;
  flex: 1;  
  max-width: 300px;
}


.card{
  border-radius: 3px 3px 0 0;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 15%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 1.5rem;

  margin: 25px 0;

  display: flex;
  align-items: flex-start;
  flex: 1;  
}

.card:first-of-type {
  margin-top: 15px;
}

.content-container {
  flex: 1;
  width: 100%;
}

.user-logo {
  margin-right: 15px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 12px;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

.content, .username {
  font-size: 18px;
  line-height: 23px;
}

.username {
  font-weight: 600;
  margin: 5px 0;
  display: inline-block;
  color: #414141;
  text-decoration: none;
}

.content__text {
  flex: 1;
  word-break: break-word;
  text-align: left;
}

a.link {
  color: #1f65ec;
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
}

em {
  background-color: rgba(84,104,255,.1);
  color: #5468ff;
  font-style: normal;
}

.search-results{
  position: absolute;
  top: 60px;
  width: 400px;
  box-sizing: border-box;
  z-index: 99991;
  display: flex;
  justify-content: center;  
  box-shadow: 0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 15%);
  max-width: 97%;
}

.search-results-arrow{
  position: absolute;
  transform: rotate(45deg);
  width: 14px;
  height: 14px;
  background-color: white;
  top: -7px;
  box-shadow: 0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 15%);
}

.search-results-content{
  background-color: white;
  border-radius: 3px;  
  max-height: 400px;
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%;
  position: relative;
  z-index: 99991;
  padding: 10px 0;
}

a.user-container{
  display: flex;
  padding: 10px 10px;
  color: black;
  text-decoration: none;  
}

.profile-image{
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;  
}

.profile-image > img{
  width: 44px;
  height: 44px;
  display: block;
}

.user-details{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  justify-content: center;
}

.user-name{
  font-weight: 500;
}

.user-description{
  text-align: left;
  font-size: 15px;
  word-break: break-word;
}

.user-container:hover {
  background-color: #7d75ff29;
  cursor: pointer;
}

.search-results-shadow{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(to bottom,rgba(255,255,255,0) 0%,rgb(255 255 255) 100%);
  border-radius: 0px;
  z-index: 99994;
}

@media screen and (max-width: 740px){
  .container {
    padding: 0;
}
}