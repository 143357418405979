.container{
    
}

.userBox{
    display: flex;
    padding: 10px 0;
}

.userDescription{
    font-size: 16px;
    line-height: 18px;
    color: #262626;    
}

.avatarContainer{
    margin-right: 30px;
    justify-content: center;
    display: flex;
    flex: 1;   
}

.userStats{
    margin-bottom: 20px;
}

.info{
    flex-basis: 30px;
    -webkit-box-flex: 2;
    flex-grow: 2;    
}

.avatarFrame{
    height: 150px;
    width: 150px;
    padding: 4px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 5px #7d75ff;
    box-sizing: border-box;
}

.avatarImage{
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0 0 2px #000000;
    background-position: center;
}

.userName{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    color: #262626;
    font-weight: 300;
    font-size: 28px;
    line-height: 37px;   
    margin-bottom: 20px;   
}

@media screen and (max-width: 740px){
    .userBox {
        flex-direction: column;
    }

    .info {
        padding: 14px 16px;
    }    

    .avatarContainer {
        margin-right: 0;
        justify-content: flex-start;
        padding: 0 16px;
    }    
}
