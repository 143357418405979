.container{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header{
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-bottom: 1px solid #ebebeb;
    box-sizing: border-box;
}

.main{
    flex: 1;
    color: #262626;
    word-wrap: break-word;
    line-height: 18px;
    font-size: 14px;  
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid #ebebeb;
}

.comments{
    position: absolute;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;    
    overflow: hidden;
    overflow-y: auto;
    padding: 14px 16px;    
    word-break: break-word;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */    
}

/* Hide scrollbar for Chrome, Safari and Opera */
.comments::-webkit-scrollbar {
    display: none;
  }

.footer{
    padding: 14px 16px;
    box-sizing: border-box;
}

a.username{
    color: #262626;
    line-height: 18px;
    font-size: 14px;
    text-decoration: none;
    margin-left: 14px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;    
}

a.username:hover{
    text-decoration: underline;
}

.avatarFrame{
    height: 36px;
    width: 36px;
    padding: 2px;
    border-radius: 50%;
    background: white;
    border: 3px solid #7d75ff;
    box-sizing: border-box;
}

.avatarImage{
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0 0 2px #000000;    
    background-position: center;
}

.stats{
    display: flex;
    font-weight: 600;
    color: #262626;
    font-size: 14px;
    line-height: 18px;    
}

.stats > div + div{
    margin-left: 15px;
}

.date{
    font-size: 11px;
    text-transform: uppercase;
    line-height: 18px;
    letter-spacing: .2px;
    color: #8e8e8e;    
}

@media screen and (max-width: 740px){
    .main{
        display: none;
    }

    .header{
        position: absolute;
        top: 60px;
        z-index: 100;
        left: 0;
        right: 0;        
    }
}