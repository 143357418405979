.container{

}

.gallery{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px -7px;
    justify-content: center;    
}

.photoContainer{
    background-color: #e6e6e6;
    width: 293px;
    height: 293px;
    margin: 14px;
}

.photo{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

@media screen and (max-width: 740px){
    .gallery{
        margin: 0;
    }    

    .photoContainer {
        width: 210px;
        height: 210px;
        margin: 6px;
    }    
}

