.container{

}

.photoContainer{
    display: flex;
    max-width: 815px;
    margin: 0 auto;    


    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    box-sizing: border-box;

    margin-bottom: 40px;
}

.photo{

}

.photoContent{
    background-color: #e6e6e6;
    padding-top: 125%;
    width: 100%;
    max-width: 100%;
    height: 0;
    position: relative;
    box-sizing: border-box; 
}

.detailsContainer{
    width: 335px;
    min-width: 335px;
    max-width: 335px;
    border-left: 1px solid #ebebeb;
}

.photoContainer > div{
    flex: 1;
    width: 50%;
}

.image{
    top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;    
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;    
}

.message a{
    color: inherit;
}

.message a:hover{
    text-decoration: underline;
}

@media screen and (max-width: 740px){
    .photoContainer {
        background: transparent;
        flex-direction: column;
        border: none;    
        margin-bottom: 20px;
    }    

    .photoContainer > div{
        width: 100%;
    }    

    .detailsContainer {
        width: 100%;
        max-width: 100%;
        border-left: none;
    }    

    .photo {
        padding-top: 65px;
    }    

    .message{
        padding: 0 16px;
        padding-bottom: 15px;        
    }
}